import React from 'react'
import Layout from '../components/layout'
import Intro from '../components/intro'
import Header from '../components/header'
import Adventure from '../components/adventure'
import Information from '../components/information'
import Booking from '../components/booking'
import Quote from '../components/quote'
import { graphql } from 'gatsby'

export default ({ data }) => (
  <Layout>
    <Header
      background={data.home.frontmatter.image.childImageSharp.fixed}
      title={data.home.frontmatter.heroTitle}
      seoTitle={data.home.frontmatter.seoTitle}
      seoDescription={data.home.frontmatter.seoDescription}
    />
    <Intro description={data.home.frontmatter.introText} />
    <Quote
      photo={data.home.frontmatter.quoteImage1.childImageSharp.fluid}
      text={data.home.frontmatter.quoteText1}
    />
    <Adventure
      background="adventure"
      itineraries={data.routes.edges.map(({ node }) => node.frontmatter)}
    >
      <h2>{data.home.frontmatter.tipsTitle}</h2>
      {data.home.frontmatter.tipsDescription.split("\n\n").map((text, index) => <p key={index}>{text}</p>)}
    </Adventure>
    <Quote
      dark
      withMargin={true}
      photo={data.home.frontmatter.quoteImage2.childImageSharp.fluid}
      text={data.home.frontmatter.quoteText2}
    />
    <Information />
    <Quote
      photo={data.home.frontmatter.quoteImage3.childImageSharp.fluid}
      text={data.home.frontmatter.quoteText3}
    />
    <Booking />
  </Layout>
)

export const query = graphql`
  query Home {
    home: markdownRemark(fileAbsolutePath: { glob: "**/home.md" }) {
      frontmatter {
        seoTitle
        seoDescription
        heroTitle
        image {
          childImageSharp {
            fixed(width: 1600, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        introText
        tipsTitle
        tipsDescription
        quoteImage1 {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 960, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        quoteText1
        quoteImage2 {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 960, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        quoteText2
        quoteImage3 {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 960, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        quoteText3
      }
    }
    routes: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/routes/" } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            image {
              childImageSharp {
                fixed(width: 720, height: 480, quality: 80) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            subtitle
            url
          }
        }
      }
    }
  }
`
